/* Bulma mixins */
.p-contact .c-data {
  margin-bottom: 2rem;
  display: grid;
  grid-template-areas: "address" "email" "org" "phone";
}
.p-contact .c-data .c-data__address {
  grid-area: address;
}
.p-contact .c-data .c-data__email {
  grid-area: email;
}
.p-contact .c-data .c-data__org {
  grid-area: org;
}
.p-contact .c-data .c-data__phone {
  grid-area: phone;
}
.p-contact .c-data__link {
  color: var(--color-blue);
  text-decoration: underline;
}
@media (hover: hover) {
  .p-contact .c-data__link:hover {
    text-decoration: none;
  }
}
.p-contact .c-data__link--phone {
  text-decoration: none;
}
@media (hover: hover) {
  .p-contact .c-data__link--phone:hover {
    text-decoration: underline;
  }
}
.p-contact .c-data__email {
  margin-bottom: 1rem;
}
@media (min-width: 600px) {
  .p-contact .c-data {
    margin-bottom: 1rem;
    grid-template-areas: "address org" "email phone";
  }
  .p-contact .c-data__email,
  .p-contact .c-data__phone {
    margin-top: 1rem;
  }
}

.p-contact {
  display: grid;
  gap: 2rem 4rem;
  margin-bottom: 3rem;
}
.p-contact .heading2 {
  margin-bottom: 0.3em;
}

.p-contact__map img {
  display: inline-flex;
  width: 600px;
  max-width: 100%;
}

@media (min-width: 1000px) {
  .p-contact {
    column-gap: 4rem;
    grid-template-columns: 1fr 1fr;
  }
  .p-contact__map {
    margin-top: 0;
  }
  .p-contact__map img {
    height: auto;
  }
}