/* Introducing a V5 version of the breakpoint functions and mixins because of this breaking change:

   Media query mixins parameters have changed for a more logical approach.
   media-breakpoint-down() uses the breakpoint itself instead of the next breakpoint (e.g., media-breakpoint-down(lg) instead of media-breakpoint-down(md) targets viewports smaller than lg).
   Similarly, the second parameter in media-breakpoint-between() also uses the breakpoint itself instead of the next breakpoint (e.g., media-between(sm, lg) instead of media-breakpoint-between(sm, md) targets viewports between sm and lg).

   Source: https://getbootstrap.com/docs/5.0/migration/#sass
*/
/* Bulma mixins */
.p-subscribe .c-topbar {
  height: 72px;
  background: var(--color-blue);
  margin-bottom: 2rem;
}

.p-subscribe .c-video {
  position: relative;
  margin-top: 2rem;
  position: relative;
  display: flex;
}
.p-subscribe .c-video:after {
  content: "";
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  transition: background 0.3s;
}
.p-subscribe .c-video .c-video__img {
  display: block;
  width: 100%;
  aspect-ratio: 16/9;
}
.p-subscribe .c-video .c-video__playbutton {
  z-index: 10;
  display: block;
  width: 80px;
  height: 80px;
  padding-top: 14px;
  padding-left: 8px;
  line-height: 20px;
  border: 6px solid #fff;
  border-radius: 50%;
  color: #f5f5f5;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.95);
}
@media (hover: hover) {
  .p-subscribe .c-video:hover:after {
    content: "";
    background: rgba(0, 0, 0, 0.5);
  }
  .p-subscribe .c-video:hover .c-video__playbutton {
    box-shadow: 0px 0px 10px var(--color-blue);
    transform: translate(-50%, -50%) scale(1.15);
  }
}

.p-subscribe .wh-form__fieldgroup[data-wh-form-group-for=damage_free] .wh-form__label .exclgebrsporen {
  font-size: 14px;
  font-weight: 500;
  display: block;
}
@media (min-width: 1024px) {
  .p-subscribe .wh-form__fieldgroup[data-wh-form-group-for=damage_free] .wh-form__label .exclgebrsporen {
    display: inline;
  }
}
.p-subscribe .wh-form {
  margin-bottom: 25px;
}
.p-subscribe .wh-form:not(.wh-form--allowprevious) .wh-form__button--previous {
  opacity: 0;
  pointer-events: none;
}
.p-subscribe .wh-form__page {
  margin-top: 4rem;
}
.p-subscribe .wh-form__navbuttons .subscribe__payoff {
  display: none !important;
}
@media screen and (max-width: 959px) {
  .p-subscribe .wh-form__buttongroup {
    flex-direction: column;
    align-items: center;
  }
  .p-subscribe .wh-form__button--previous {
    order: 2;
    margin-top: 0;
  }
  .p-subscribe .wh-form__button--next {
    margin-top: 0;
    margin-bottom: 5px;
    width: 100%;
  }
}
@media screen and (max-width: 425px) {
  .p-subscribe .wh-form .wh-form__fieldgroup--radiogroup {
    flex-direction: column;
  }
  .p-subscribe .wh-form .wh-form__fieldgroup--radiogroup .wh-form__fieldline:first-child {
    margin-left: 0;
  }
  .p-subscribe .wh-form .wh-form__fieldgroup--radiogroup .wh-form__fields {
    margin-top: 15px;
  }
}
@media screen and (max-width: 959px) {
  .p-subscribe .wh-form--allowsubmit .wh-form__navbuttons .subscribe__payoff {
    display: block;
    text-align: center;
    margin-top: 12px;
  }
}

html.page-subscribe .c-textareacounter {
  text-align: right;
}
html.page-subscribe .ph--star,
html.page-subscribe .ph--star-fill {
  display: inline-block;
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  margin-right: 4px;
}
html.page-subscribe .ph--star[data-index="3"],
html.page-subscribe .ph--star-fill[data-index="3"] {
  margin-right: 0;
}
html.page-subscribe .ph--star {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='%23f6a713' d='M239.2 97.29a16 16 0 0 0-13.81-11L166 81.17l-23.28-55.36a15.95 15.95 0 0 0-29.44 0L90.07 81.17l-59.46 5.15a16 16 0 0 0-9.11 28.06l45.11 39.42l-13.52 58.54a16 16 0 0 0 23.84 17.34l51-31l51.11 31a16 16 0 0 0 23.84-17.34l-13.51-58.6l45.1-39.36a16 16 0 0 0 4.73-17.09m-15.22 5l-45.1 39.36a16 16 0 0 0-5.08 15.71L187.35 216l-51.07-31a15.9 15.9 0 0 0-16.54 0l-51 31l13.46-58.6a16 16 0 0 0-5.08-15.71L32 102.35a.37.37 0 0 1 0-.09l59.44-5.14a16 16 0 0 0 13.35-9.75L128 32.08l23.2 55.29a16 16 0 0 0 13.35 9.75l59.45 5.14v.07Z'/%3E%3C/svg%3E");
}
html.page-subscribe .ph--star-fill {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'%3E%3Cpath fill='%23f6a713' d='m234.5 114.38l-45.1 39.36l13.51 58.6a16 16 0 0 1-23.84 17.34l-51.11-31l-51 31a16 16 0 0 1-23.84-17.34l13.49-58.54l-45.11-39.42a16 16 0 0 1 9.11-28.06l59.46-5.15l23.21-55.36a15.95 15.95 0 0 1 29.44 0L166 81.17l59.44 5.15a16 16 0 0 1 9.11 28.06Z'/%3E%3C/svg%3E");
}
html.page-subscribe #categorie1,
html.page-subscribe #categorie3 {
  display: none;
}
html.page-subscribe .headerimg__titlewrapper {
  height: 135px;
  padding: 0;
  min-height: 0;
}
html.page-subscribe .hamburger {
  padding-bottom: 0;
}
html.page-subscribe .header {
  padding-top: 10px;
  padding-bottom: 10px;
}
html.page-subscribe .header__menu,
html.page-subscribe .header__reviews,
html.page-subscribe .header__openingtimes,
html.page-subscribe .headerimg__backgroundcontainer {
  display: none !important;
}
html.page-subscribe .headerimg__container {
  margin-bottom: 1rem;
}
html.page-subscribe .wh-form .wh-form__textinput,
html.page-subscribe .wh-form .wh-form__textarea,
html.page-subscribe .wh-form .wh-form__pulldown {
  margin-top: 0;
}
html.page-subscribe .wh-form .wh-form__fieldgroup {
  margin-bottom: 0;
}
html.page-subscribe .wh-form__page {
  margin-top: 2.5rem;
}
html.page-subscribe .wh-form__page--2 .wh-form__fieldgroup {
  margin-top: 11px !important;
}
html.page-subscribe .wh-form__page--2 .wh-form__fieldgroup--radiogroup {
  padding-bottom: 11px !important;
}
html.page-subscribe .wh-form__page--2 .wh-form__textarea {
  margin-bottom: 0;
  min-height: 100px;
}
html.page-subscribe .wh-form__optiondata:last-child {
  margin-right: 0 !important;
}
html.page-subscribe .subscribe__star-rating {
  line-height: 100%;
}
html.page-subscribe .subscribe__options .wh-form__fieldline {
  margin-bottom: 10px;
}
html.page-subscribe .wh-form__fieldgroup[data-wh-form-group-for=options] .wh-anchor {
  display: none;
}
@media (max-width: 759px) {
  html.page-subscribe .wh-form__fieldgroup[data-wh-form-group-for=wrd_lastname] {
    order: 1;
  }
  html.page-subscribe .wh-form__fieldgroup[data-wh-form-group-for=phone] {
    order: 2;
  }
  html.page-subscribe .wh-form__fieldgroup[data-wh-form-group-for=email] {
    order: 3;
  }
  html.page-subscribe .wh-form__fieldgroup[data-wh-form-group-for=expected_price] {
    order: 15;
  }
}
@media (min-width: 760px) {
  html.page-subscribe .headerimg__backgroundcontainer {
    display: block;
  }
  html.page-subscribe .headerimg__container {
    padding: 0;
  }
  html.page-subscribe .headerimg__titlewrapper {
    display: block;
  }
}

.p-subscribe {
  margin-top: 2rem;
}
.p-subscribe .subscribe__firstpageheader {
  margin-bottom: 8px;
}
.p-subscribe h2 {
  font-size: 24px;
  line-height: 40px;
}
.p-subscribe.async-hide .lds-ring-container {
  background: rgba(255, 255, 255, 0.8);
}
.p-subscribe .subscribe__cols {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 3rem;
}
.p-subscribe .subscribe__help p {
  display: none;
}
.p-subscribe .subscribe__groupedfields {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 2rem;
}
.p-subscribe .subscribe__options {
  margin-bottom: 3rem;
}
.p-subscribe .subscribe__payoff {
  margin: 4px 0 12px;
  text-align: right;
  font-weight: 650;
  color: #626262;
}
.p-subscribe .subscribe__star-rating .wh-form__fieldgroup--pulldown {
  margin: 0 0 25px;
  border-bottom: 3px solid #dae4ed;
  padding: 0 0 5px 0;
}
.p-subscribe .subscribe__star-rating select,
.p-subscribe .subscribe__star-rating .wh-form__fieldline:after {
  display: none !important;
}
.p-subscribe .subscribe__star-rating .wh-form__fieldgroup {
  display: flex;
  justify-content: space-between;
}
.p-subscribe .subscribe__star-rating__label {
  font-family: Raleway, sans-serif;
  text-align: right;
  font-size: 16px;
  margin-top: 10px;
}
.p-subscribe #subscribe-make:after {
  content: " ";
}

@media (min-width: 600px) {
  .p-subscribe .subscribe__cols {
    display: grid;
    grid-template-columns: 1fr 300px;
    column-gap: 2rem;
  }
  .p-subscribe .subscribe__help {
    display: block !important;
    margin-top: 0;
  }
}
@media (max-width: 799px) {
  html.page-subscribe .header {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (min-width: 800px) {
  .p-subscribe .subscribe__cols {
    display: grid;
    grid-template-columns: 1fr 380px;
    column-gap: 2rem;
  }
}
@media (min-width: 1024px) {
  .p-subscribe {
    margin-top: 2rem;
  }
  html.page-subscribe .header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  html.page-subscribe .header__openingtimes,
  html.page-subscribe .header__reviews {
    display: flex !important;
  }
  .p-subscribe .subscribe__cols {
    column-gap: 3rem;
    grid-template-columns: 1fr 440px;
  }
  .p-subscribe .subscribe__options {
    margin-bottom: 0;
  }
  .p-subscribe .subscribe__options .wh-form__fields {
    display: grid;
    column-gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (max-width: 425px) {
  .subscribe__star-rating .wh-form__fieldgroup {
    flex-direction: column;
  }
  .subscribe__star-rating .wh-form__label {
    margin-bottom: 15px;
  }
  .subscribe__star-rating__label {
    text-align: left;
  }
}
@media screen and (max-width: 900px) {
  .wh-form__page[data-wh-form-pagerole=thankyou] .subscribe__cols {
    flex-direction: column;
  }
  .wh-form__page[data-wh-form-pagerole=thankyou] .subscribe__help,
  .wh-form__page[data-wh-form-pagerole=thankyou] .subscribe__col--right {
    display: block !important;
  }
  .wh-form__page[data-wh-form-pagerole=thankyou] .subscribe__col--right {
    margin-left: 0;
  }
}

.subscribe__options-category {
  display: block;
  font-weight: bold;
  margin-top: 1.5rem;
  font-size: 16px;
}

@media (max-width: 1023px) {
  .wh-form__page--3 .wh-form__fields {
    flex-direction: column !important;
  }
}
@media (min-width: 1024px) {
  .subscribe__options-category-group .subscribe__options-category {
    margin-top: 0;
  }
  .subscribe__options-category-group[data-idx="0"] {
    grid-area: group0;
  }
  .subscribe__options-category-group[data-idx="1"] {
    grid-area: group1;
  }
  .subscribe__options-category-group[data-idx="2"] {
    grid-area: group2;
  }
  .p-subscribe .subscribe__options .wh-form__fields {
    display: block;
  }
  .subscribe__options-outer {
    display: grid;
    width: 100%;
    column-gap: 2rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "group2 group0" "group2 group1";
  }
}