/* Bulma mixins */
.ww-arrow {
  display: inline-block;
  height: 12px;
  position: relative;
  width: 12px;
}
.ww-arrow::after {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
  content: "";
  display: inline-block;
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
  width: 12px;
}
.ww-arrow.ww-arrow--triangle::after {
  border-style: solid;
  border-width: 0 0 12px 12px;
  height: 0;
  width: 0;
}
.ww-arrow.ww-arrow--check::after {
  width: calc(12px / 2);
}
.ww-arrow.ww-arrow--bar::before {
  bottom: 1px;
  content: "";
  height: 20px;
  position: absolute;
  right: 0;
  transform-origin: bottom;
  width: 2px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.ww-arrow.ww-arrow--top {
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.ww-arrow.ww-arrow--right {
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}
.ww-arrow.ww-arrow--bottom {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ww-arrow.ww-arrow--left {
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.ww-arrow::after,
.ww-arrow .ww-arrow::after {
  border-color: var(--ww-primary-color);
}
.ww-arrow.ww-arrow--triangle::after,
.ww-arrow .ww-arrow.ww-arrow--triangle::after {
  border-color: transparent;
  border-bottom-color: var(--ww-primary-color);
}
.ww-arrow::before,
.ww-arrow .ww-arrow::before {
  background-color: var(--ww-primary-color);
}

.licenseform__title {
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 30px;
}
.licenseform__title strong {
  font-weight: 800;
}

.licenseform__plate {
  font-size: 36px;
  line-height: 100%;
  line-height: 65px;
  background-color: var(--color-orange);
  border: 0;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 auto;
  background-image: url("../../web/img/kenteken_nl.jpg");
  background-repeat: no-repeat;
  background-size: 31px;
  max-width: 100%;
  width: 320px;
  padding-left: 1rem;
}
.licenseform__plate::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 16px;
  font-family: Raleway, sans-serif;
  font-weight: 500;
  text-transform: initial;
  color: #000;
  text-align: center;
}
.licenseform__plate:-moz-placeholder {
  font-size: 16px;
  line-height: 16px;
  font-family: Raleway, sans-serif;
  font-weight: 500;
  text-transform: initial;
  color: #000;
  text-align: center;
}
.licenseform__plate::-moz-placeholder {
  font-size: 16px;
  line-height: 16px;
  font-family: Raleway, sans-serif;
  font-weight: 500;
  text-transform: initial;
  color: #000;
  text-align: center;
}
.licenseform__plate:-ms-input-placeholder {
  font-size: 16px;
  line-height: 16px;
  font-family: Raleway, sans-serif;
  font-weight: 500;
  text-transform: initial;
  color: #000;
  text-align: center;
}
.licenseform__plate:placeholder-shown {
  font-size: 16px;
  line-height: 16px;
  font-family: Raleway, sans-serif;
  font-weight: 500;
  text-transform: initial;
  color: #000;
  text-align: center;
}
.licenseform__plate:focus {
  outline: 0;
}

.licenseform__forgot {
  color: var(--color-blue);
  transition: color 0.3s;
}
.licenseform__forgot:hover {
  color: var(--color-orange);
}

@media screen and (max-width: 768px) {
  .licenseform__plate {
    font-size: 30px;
    line-height: 100%;
  }
}
.licenseform__plate,
.licenseform__submitbutton {
  height: 60px;
}

.licenseform__submitbuttonarrow {
  margin-left: 4px;
  display: inline-block;
  height: 8px;
  position: relative;
  width: 8px;
}
.licenseform__submitbuttonarrow::after {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
  content: "";
  display: inline-block;
  height: 8px;
  left: 0;
  position: absolute;
  top: 0;
  width: 8px;
}
.licenseform__submitbuttonarrow.ww-arrow--triangle::after {
  border-style: solid;
  border-width: 0 0 8px 8px;
  height: 0;
  width: 0;
}
.licenseform__submitbuttonarrow.ww-arrow--check::after {
  width: calc(8px / 2);
}
.licenseform__submitbuttonarrow.ww-arrow--bar::before {
  bottom: 1px;
  content: "";
  height: 25px;
  position: absolute;
  right: 0;
  transform-origin: bottom;
  width: 2px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.licenseform__submitbuttonarrow.ww-arrow--top {
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.licenseform__submitbuttonarrow.ww-arrow--right {
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}
.licenseform__submitbuttonarrow.ww-arrow--bottom {
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.licenseform__submitbuttonarrow.ww-arrow--left {
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.licenseform__submitbuttonarrow::after,
.licenseform__submitbuttonarrow .ww-arrow::after {
  border-color: #fff;
}
.licenseform__submitbuttonarrow.ww-arrow--triangle::after,
.licenseform__submitbuttonarrow .ww-arrow.ww-arrow--triangle::after {
  border-color: transparent;
  border-bottom-color: #fff;
}
.licenseform__submitbuttonarrow::before,
.licenseform__submitbuttonarrow .ww-arrow::before {
  background-color: #fff;
}

/*Need to fix*/
#licenseform h2 {
  margin-bottom: 1rem;
}

.licenseform__outertitle {
  display: none;
}

.licenseform__customers {
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
}

.licenseform__customers--title {
  font-weight: bold;
  color: #000;
}

.licenseform__plate {
  border-radius: 0;
  width: 100%;
  text-align: center;
}

.licenseform__title {
  color: var(--color-blue);
  margin: 0px;
  font-size: 26px;
  line-height: 100%;
  text-align: center;
}

.licenseform__outer--header .licenseform__plate--formwrapper {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}
.licenseform__outer--header .licenseform__plate--formwrapper .licenseform__plate,
.licenseform__outer--header .licenseform__plate--formwrapper .licenseform__submitbutton {
  width: 100%;
  max-width: 400px;
}
@media (min-width: 1024px) {
  .licenseform__outer--header .licenseform__plate--formwrapper .licenseform__plate,
  .licenseform__outer--header .licenseform__plate--formwrapper .button {
    width: 100%;
  }
  .licenseform__outer--header .licenseform__title {
    font-size: 32px;
    line-height: 100%;
    margin-bottom: 1rem;
  }
  .licenseform__outer--header .licenseform__customers {
    margin-bottom: 0px;
  }
  .licenseform__outer--header .licenseform__customers--people {
    filter: invert(50%) sepia(17%) saturate(1406%) hue-rotate(166deg) brightness(84%) contrast(86%);
  }
}

.licenseform__outer--small .licenseform__plate--formwrapper,
.licenseform__outer--sidebar .licenseform__plate--formwrapper {
  margin-bottom: 1rem;
}
.licenseform__outer--small .licenseform,
.licenseform__outer--sidebar .licenseform {
  background: #fff;
  padding: 2rem;
}
.licenseform__outer--small .licenseform__customers,
.licenseform__outer--small .licenseform__title,
.licenseform__outer--small .licenseform__subtitle,
.licenseform__outer--sidebar .licenseform__customers,
.licenseform__outer--sidebar .licenseform__title,
.licenseform__outer--sidebar .licenseform__subtitle {
  display: none !important;
}
.licenseform__outer--small .licenseform__plate,
.licenseform__outer--sidebar .licenseform__plate {
  margin-bottom: 1rem;
  text-align: center;
}
.licenseform__outer--small .button,
.licenseform__outer--sidebar .button {
  width: 100%;
}

.licenseform__outer--sidebar,
.licenseform__outer--sidebar .licenseform {
  background: var(--color-blue);
}
.licenseform__outer--sidebar .licenseform {
  padding-top: 0;
}
.licenseform__outer--sidebar .licenseform__plate {
  margin-bottom: 1rem;
}
.licenseform__outer--sidebar .licenseform__outertitle {
  font-size: 30px;
  line-height: 35px;
  display: block;
  padding: 1.5rem;
  color: #fff;
  font-weight: 900;
}
.licenseform__outer--sidebar .licenseform__forgotcontainer {
  margin-top: 1rem;
}
.licenseform__outer--sidebar .licenseform__forgot {
  text-decoration: underline;
  color: #fff !important;
}
@media (hover: hover) {
  .licenseform__outer--sidebar .licenseform__forgot:hover {
    text-decoration: none;
  }
}