/* Bulma mixins */
.w-team.w-team--swiper-disabled .swiper-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: 1rem;
}
.w-team.w-team--swiper-disabled .swiper-prev,
.w-team.w-team--swiper-disabled .swiper-next,
.w-team.w-team--swiper-disabled .swiper-pagination {
  display: none;
}

.w-team:not(.w-team--swiper-disabled) {
  margin-bottom: 80px;
}

.w-team__swiper-pagination {
  bottom: -30px !important;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  column-gap: 2px;
}
.w-team__swiper-pagination .swiper-pagination-bullet {
  pointer-events: none;
}

.w-team__member {
  aspect-ratio: 1/1;
  position: relative;
  overflow: hidden;
}

.w-team__textcontainer {
  color: #fff;
  background: var(--color-blue);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  padding: 0 26px;
  overflow: hidden;
  transition: height 0.3s, margin 0.3s;
  overflow-y: auto;
}

.w-team__titles {
  padding: 26px 0 21px;
  height: 100%;
  overflow-y: auto;
}

.w-team__title {
  font-size: 36px;
  line-height: 40px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 6px;
}

.w-team__subtitle {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  text-transform: uppercase;
}

.w-team__description {
  line-height: 26px;
  padding-bottom: 1rem;
  max-height: 110px;
  overflow-y: auto;
}

.w-team__overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.w-team .swiper-container {
  width: 100%;
}

.w-team .swiper-prev,
.w-team .swiper-next {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 10;
}
.w-team .swiper-prev.swiper-button-disabled,
.w-team .swiper-next.swiper-button-disabled {
  opacity: 0.3;
}

.w-team .swiper-prev {
  right: auto;
  left: 10px;
  transform: translateY(-50%) scale(-1, 1);
}

.w-team__member--hasdescription.hover .w-team__overlay {
  opacity: 1;
}