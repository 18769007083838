/* Bulma mixins */
.header {
  background: #fff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  padding: 12px 1rem;
}

.header .__fbcw__bar-widget {
  justify-content: center !important;
}

.header__outer {
  max-width: 1280px;
  margin: 0 auto;
}

.header__logo {
  height: 40px;
}

.header__container {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-rows: auto auto auto;
  gap: 1rem 0;
  grid-auto-flow: column;
  grid-template-areas: "header__hamburger header__openingtimes  header__phone" "header__hamburger header__logocontainer header__phone" "header__hamburger header__reviews       header__phone";
}

.header__phone {
  grid-area: header__phone;
}

.header__hamburger {
  grid-area: header__hamburger;
}

.header__openingtimes {
  grid-area: header__openingtimes;
}

.header__logocontainer {
  grid-area: header__logocontainer;
}

.header__reviews {
  grid-area: header__reviews;
}

.header__menu {
  grid-area: header__menu;
}

#header-phoneicon {
  background: #5bc762;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  transform: scale(1);
  animation: pulse 2s infinite;
}

#header-openingtimes-container {
  display: none;
}
#header-openingtimes-container.enabled {
  display: flex !important;
}
@media (hover: hover) {
  #header-openingtimes-container:hover {
    color: var(--color-orange) !important;
  }
}

#header-openingtimes {
  text-decoration: none;
}

#header-openingtimes-text {
  font-size: 13px;
  line-height: 100%;
  font-weight: 500;
  color: var(--color-blue);
  text-align: center;
  transition: color 0.3s;
}
@media (hover: hover) {
  #header-openingtimes-text:hover {
    color: var(--color-orange) !important;
  }
}

.header__mobile .header__phonelink {
  text-decoration: none;
}
.header__mobile .icon-phone {
  color: var(--color-orange);
  font-size: 18px;
  width: 32px;
  height: 32px;
  background: #fff;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  border-radius: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s, color 0.3s, transform 0.3s;
}
@media (hover: hover) {
  .header__mobile .icon-phone:hover {
    background: var(--color-orange);
    color: #fff;
    transform: scale(1.2);
  }
}
.header__mobile .header__phonetext {
  display: none;
}

.header__desktop .icon-phone {
  font-size: 20px;
  color: var(--color-orange);
  margin-right: 6px;
}
.header__desktop .header__phonelink {
  text-decoration: none;
}

.header__phonetext {
  white-space: nowrap;
  font-weight: bold;
  font-size: 20px;
  color: var(--color-blue);
  transition: color 0.3s;
}
@media (hover: hover) {
  .header__phonetext:hover {
    color: var(--color-orange);
  }
}

.header__stars .icon-star {
  display: flex;
  align-items: center;
  color: var(--color-orange);
  font-size: 16px;
}

.header__reviews {
  font-size: 13px;
  line-height: 100%;
  font-weight: 600;
  color: inherit;
}

.header__list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  text-transform: uppercase;
  column-gap: 1rem;
}

.header__menuitem {
  line-height: 100%;
}
.header__menuitem--selected .header__menulink {
  color: var(--color-orange);
}

.header__rightpart {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.header__toppart {
  column-gap: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.header__menulink {
  font-size: 15px;
  line-height: 100%;
  color: var(--color-blue);
  text-decoration: none;
  white-space: nowrap;
  font-weight: 500;
}
.header__menulink:hover {
  color: var(--color-orange);
  text-decoration: underline;
}

@media (max-width: 1023px) {
  .header__reviewstext {
    font-size: 120%;
    font-weight: bold;
  }
}
@media (min-width: 1024px) {
  .header {
    padding: 20px 1rem;
  }
  .header__reviews {
    font-size: 16px;
    line-height: 100%;
  }
  .header__phonetext {
    font-size: 24px;
  }
  .header__phone .icon-phone {
    font-size: 24px;
  }
  .header__logo {
    height: 53px;
  }
  .header__container {
    grid-template-areas: "header__logocontainer header__openingtimes header__reviews header__phone" "header__logocontainer header__menu         header__menu    header__menu";
    grid-template-columns: minmax(300px, 1fr) min-content min-content min-content;
    grid-template-rows: auto auto;
    margin: 0 auto;
    max-width: 1280px;
    gap: 20px 35px;
  }
  .header__logocontainer {
    justify-content: flex-start !important;
  }
  .header__hamburger {
    display: none !important;
  }
  .header__openingtimes,
  .header__phone,
  .header__reviews {
    white-space: nowrap;
  }
  #header-openingtimes-text {
    font-size: 14px;
  }
  .header__toppart {
    display: grid;
    grid-template-areas: "header__openingtimes header__openingtimes" "header__reviews header__phone";
    grid-template-columns: 1fr auto;
    gap: 16px 16px;
  }
  .header__toppart .header__reviews {
    justify-self: center;
  }
  .header__openingtimes {
    justify-content: flex-end !important;
  }
  .header__logocontainer {
    min-width: 200px;
  }
}
@media (min-width: 1100px) {
  .header__list {
    column-gap: 24px;
  }
  .header__toppart {
    column-gap: 40px;
  }
}
@media (min-width: 1216px) {
  .header__menulink {
    font-size: 16px;
    line-height: 100%;
  }
  .header__reviewstext {
    font-size: 16px;
    line-height: 100%;
    font-weight: bold;
  }
  .header__reviewstextnum {
    font-size: 1.75em;
  }
  .header__stars .icon-star {
    font-size: 20px;
  }
  .header__container {
    column-gap: 50px;
  }
  .header__phonetext {
    font-size: 28px;
  }
  .header__phone .icon-phone {
    font-size: 28px;
  }
}
#menu_mobile {
  transform: translateX(-150vw);
  pointer-events: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999999;
  transition: transform 0.4s;
  display: block !important;
}
#menu_mobile .hamburger-inner,
#menu_mobile .hamburger-inner::before,
#menu_mobile .hamburger-inner::after {
  background-color: #fff;
}
#menu_mobile.show {
  transform: translateX(0);
  pointer-events: auto;
}

@media only screen and (max-width: 1023px) {
  #menu_mobile {
    padding: 15px;
    background-color: #4679a5;
  }
  #menu_mobile ul {
    padding-left: 0;
  }
  #menu_mobile ul li {
    list-style: none;
    padding: 8px 0px;
    border-bottom: 1px dashed #fff;
  }
  #menu_mobile ul li a {
    color: #fff;
    font-size: 20px;
  }
  #menu_mobile ul li a:hover {
    text-decoration: none;
  }
}
html.hide-header-menu .header__hamburger,
html.hide-header-menu .header__mobile .header__phone,
html.hide-header-menu .header__menu {
  display: none !important;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}