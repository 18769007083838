/* Bulma mixins */
.w-howitworks__item {
  margin-bottom: 3rem;
}

.w-howitworks__item-headingmobile {
  display: grid;
  gap: 30px;
  grid-template-columns: 118px 1fr;
}

.w-howitworks__item-arrow {
  display: none;
}

.w-howitworks__item-image {
  display: flex;
  position: relative;
  margin-bottom: 90px;
}

.w-howitworks__item-image img {
  width: 100%;
  position: absolute;
  top: -30px;
  left: 0;
}

.w-howitworks__item-number {
  color: #4679A5;
  font-size: 80px;
  font-weight: bold;
}

.w-howitworks__item-subtitle {
  text-transform: uppercase;
  color: #4679A5;
}

.w-howitworks__item-text {
  margin-top: 1rem;
}

.w-howitworks__item-text .w-howitworks__item-subtitle {
  display: none;
}

.w-howitworks__item-title {
  text-transform: uppercase;
  font-size: 25px;
  line-height: 100%;
  margin-bottom: 0;
}

.w-howitworks__item.left .w-howitowrks__item-headingmobile {
  grid-auto-flow: dense;
  direction: rtl;
  gap: 1rem;
}

.w-howitworks__item.left .w-howitworks__item-image {
  flex-direction: row-reverse;
  justify-content: right;
}

@media (min-width: 900px) {
  .w-howitworks__item-text .w-howitworks__item-subtitle {
    display: block;
    visibility: hidden;
  }
  .w-howitworks__item {
    display: grid;
    grid-template-columns: 500px 1fr;
    column-gap: 2rem;
  }
  .w-howitworks__item.left .w-howitworks__item-text {
    text-align: left;
  }
  .w-howitworks__item-image {
    margin-bottom: 0;
  }
  .w-howitworks__item-headingmobile {
    gap: 0;
  }
  .w-howitworks__item-headingmobile {
    grid-template-columns: 200px 1fr;
  }
  .w-howitworks__item-image img {
    transform: translateX(-20px);
    background: white;
    padding: 15px;
    top: -50px;
  }
  .w-howitworks__item-number {
    color: #4679A5;
    font-size: 117px;
    font-weight: 900;
  }
  .w-howitworks__item-title {
    font-size: 48px;
    line-height: 100%;
  }
  .w-howitworks__item-text {
    margin-top: 0;
  }
  .w-howitworks__item.left {
    grid-auto-flow: dense;
    direction: rtl;
  }
  .w-howitworks__item.left .w-howitworks__item-image img {
    transform: translateX(20px);
  }
  .w-howitworks__item.left .w-howitworks__item-wrappper, .w-howitworks__item.left .w-howitworks__item-wrappper * {
    text-align: right !important;
  }
}
@media (min-width: 1200px) {
  .w-howitworks__item {
    grid-template-columns: 1fr 1fr;
    column-gap: 3rem;
  }
}