.ctalicense {
  --image-bottom-height: 240px;
  position: relative;
  overflow: hidden;
  background: var(--color-blue);
  padding-top: 24px;
  z-index: 10;
}
.ctalicense * {
  text-align: left;
}

.ctalicense__container {
  display: grid;
  column-gap: 60px;
  grid-template-areas: "intro" "licenseform" "imagebottom";
}

.ctalicense__intro {
  grid-area: intro;
}

.ctalicense__imagebottom {
  width: auto;
  height: var(--image-bottom-height);
  display: block;
  transform: translateY(30px);
  position: relative;
  z-index: 10;
  grid-area: imagebottom;
}

.ctalicense__clipbottomcontainer {
  position: absolute;
  bottom: -1px;
  width: 100%;
  z-index: 30;
}

.ctalicense__clipbottom {
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background: #fff;
  aspect-ratio: 375/82;
}

.ctalicense__clipbottommargin {
  background: #fff;
  height: 19px;
  position: relative;
  top: -1px;
}

.ctalicense .wvuaw-subtitle {
  font-weight: 600;
}

.ctalicense__title,
.ctalicense__subtitle,
.ctalicense .wvuaw-subtitle {
  color: #fff;
}

.ctalicense__title {
  margin-bottom: 0;
}

.ctalicense__subtitle {
  font-weight: 700;
  padding-top: 30px;
}

.ctalicense .licenseform__outer {
  margin-top: 2rem;
  grid-area: licenseform;
}

@media (min-width: 750px) {
  .ctalicense {
    --image-bottom-height: 240px;
  }
  .ctalicense__clipbottom {
    aspect-ratio: 375/40;
  }
  .ctalicense__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    grid-template-areas: "intro licenseform" "imagebottom licenseform";
  }
  .ctalicense__imagebottom {
    margin-top: 42px;
  }
}
@media (min-width: 1024px) {
  .ctalicense__container {
    grid-template-columns: 225px 1fr 1fr;
    grid-template-areas: "imagebottom intro licenseform";
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .ctalicense__imagebottom {
    width: 100%;
    height: auto;
    margin-top: 0;
    align-self: flex-end;
  }
  .ctalicense__clipbottom {
    aspect-ratio: 375/20;
  }
  .ctalicense__clipbottommargin {
    height: 28px;
  }
  .ctalicense .licenseform__outer {
    margin-bottom: 100px;
  }
}
@media (min-width: 1200px) {
  .ctalicense__container {
    grid-template-columns: 300px 1fr 1fr;
  }
  .ctalicense__subtitle {
    padding-top: 60px;
  }
  .ctalicense__imagebottom {
    width: 80%;
  }
  .ctalicense__clipbottom {
    aspect-ratio: 375/15;
  }
}