/* Bulma mixins */
.w-textimagecols {
  gap: 2rem;
  display: var(--two-columns-display);
  column-gap: var(--two-columns-gap);
  grid-template-columns: 1fr 1fr;
  flex-direction: column;
}

.w-textimagecols__image {
  display: block;
}

.w-textimagecols__textcontainer {
  padding: 0 var(--grid-padding-x);
}
.w-textimagecols__textcontainer > *:last-child {
  margin-bottom: 0;
}

.w-textimagecols--mirror .w-textimagecols__imagecontainer {
  order: 2;
}

@media (min-width: 1024px) {
  .w-textimagecols {
    --textimagecols-grid-padding: max(24px, calc(((100vw - var(--grid-max-content-width))/2)));
  }
  .w-textimagecols__imagecontainer {
    position: relative;
    margin-bottom: 0;
  }
  .w-textimagecols__textcontainer {
    padding-right: var(--textimagecols-grid-padding);
    padding-left: 0;
  }
  .w-textimagecols__image {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    max-height: 600px;
  }
  .w-textimagecols--mirror .w-textimagecols__textcontainer {
    padding-left: var(--textimagecols-grid-padding);
    padding-right: 0;
  }
}