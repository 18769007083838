/* Bulma mixins */
.threecolumns {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
}

.threecolumns__title .threecolumns__highlight {
  color: var(--color-orange);
}

@media screen and (min-width: 1024px) {
  .threecolumns {
    padding: 0px;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
  }
}