/* Bulma mixins */
.w-googlereviews__swiper {
  width: 100%;
  margin-left: 0;
}

.w-googlereviews__swiper-pagination {
  text-align: left;
  bottom: auto;
  position: relative;
  margin: 2rem 0 1rem;
  display: flex;
  justify-content: flex-start;
  column-gap: 4px;
}

.reviews {
  font-size: 16px;
  color: #000;
  font-weight: 700;
  margin-bottom: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
}
.reviews .reviews__num {
  font-size: 1.75em;
}
.reviews .icon-star {
  display: flex;
  align-items: center;
  color: var(--color-orange);
  font-size: 20px;
}

.reviews__stars {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
}

@media (min-width: 1024px) {
  .w-googlereviews .floating-arrow {
    display: block;
    right: 30vw;
    bottom: -208px;
  }
  .reviews {
    margin-bottom: 2rem;
  }
  .w-googlereviews__swiper {
    padding-bottom: 60px;
    margin-bottom: 4px;
  }
  .w-googlereviews__google-reviews-logo {
    position: absolute;
    right: 10vw;
    bottom: -90px;
    width: 185px;
  }
}
@media (min-width: 1216px) {
  .w-googlereviews .floating-arrow {
    right: 25vw;
  }
  .reviews__text {
    font-size: 16px;
    line-height: 100%;
    font-weight: bold;
  }
  .reviews .icon-star {
    font-size: 20px;
  }
}