/* Bulma mixins */
.waaromwvuaw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.waaromwvuaw .column {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  line-height: 1.5;
  border: 1px solid var(--color-blue);
  border-radius: 16px;
  padding: 1rem;
  margin: 1rem;
}
.waaromwvuaw .column span {
  font-weight: normal;
  font-size: 16px;
}
.waaromwvuaw .column p {
  height: 72px;
}
.waaromwvuaw .column h3 {
  font-size: 28px;
  color: var(--color-blue);
  border-bottom: 2px solid var(--color-orange);
  width: 40%;
}
@media (max-width: 1140px) {
  .waaromwvuaw .column span,
  .waaromwvuaw .column p {
    font-size: 14px;
  }
}
@media (max-width: 1023px) {
  .waaromwvuaw {
    flex-wrap: wrap;
  }
  .waaromwvuaw .column {
    -ms-flex-preferred-size: calc(50% - 2rem);
    flex-basis: calc(50% - 2rem);
    padding: 2rem;
  }
  .waaromwvuaw .column p {
    height: unset;
  }
}
@media (max-width: 698px) {
  .waaromwvuaw {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
  .waaromwvuaw .column {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
@media (max-width: 520px) {
  .waaromwvuaw {
    width: 100%;
  }
}

.pro-cons {
  margin: 3rem 0;
}

.pro-cons__title {
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  color: var(--color-blue);
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.pro-cons__icon {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--color-black);
  margin-bottom: 1em;
}
.pro-cons__icon img {
  margin-top: 2px;
}

.pro-cons__con {
  margin-top: 2rem;
}

@media (min-width: 600px) {
  .pro-cons__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0px;
  }
  .pro-cons__con {
    margin-top: 0;
  }
  .pro-cons__col--1 {
    grid-area: 1/1/2/3;
  }
  .pro-cons__col--2 {
    grid-area: 2/1/3/2;
  }
  .pro-cons__col--3 {
    grid-area: 2/2/3/3;
  }
}
.pro-cons__col.pro-cons__col--4 {
  margin-top: 3rem;
}

@media (min-width: 769px) {
  .pro-cons__col.pro-cons__col--4 {
    display: none;
  }
}
@media (min-width: 1024px) {
  .pro-cons {
    margin-bottom: 150px;
  }
  .pro-cons__grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
  }
  .pro-cons__col--1,
  .pro-cons__col--2,
  .pro-cons__col--3 {
    position: relative;
    grid-area: initial;
  }
  .pro-cons .floating-arrow {
    display: none;
    bottom: 0;
    right: 0;
    transform: translate(100px, calc(100% - 80px));
  }
  .p-home .pro-cons .floating-arrow {
    display: block;
  }
}