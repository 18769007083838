/* Bulma mixins */
.textbgimage__counters {
  margin-top: -3rem;
  text-align: center;
  background-color: #4679A5;
  padding: 5rem 2rem 2rem 2rem;
  color: #fff;
  font-size: 16px;
  font-weight: normal;
  clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
}

.textbgimage__counters .counter {
  font-size: 48px;
  font-weight: 900;
  color: #F6A713;
}

.textbgimage__counters div {
  margin-bottom: 2rem;
}

@media (min-width: 1024px) {
  .textbgimage__counters {
    padding: 10rem 2rem 2rem 2rem;
    clip-path: polygon(98% 0, 100% 65%, 100% 99%, 0 100%, 0 30%);
    margin: -7rem 0 0;
  }
  .textbgimage__counters .container {
    max-width: 800px !important;
    display: flex;
    justify-content: space-between;
  }
}