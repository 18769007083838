/* Bulma mixins */
.p-blogindex .c-blogindexitem {
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px dashed var(--color-orange);
}
.p-blogindex .c-blogindexitem__title {
  font-size: 22px;
  line-height: 26px;
  text-decoration: none;
  font-weight: 800;
}
@media (hover: hover) {
  .p-blogindex .c-blogindexitem__title:hover {
    text-decoration: underline;
  }
}
.p-blogindex .c-blogindexitem__image {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.p-blogindex .c-blogindexitem__image img {
  display: block;
  width: 100%;
}
.p-blogindex .c-blogindexitem__subtitle a,
.p-blogindex .c-blogindexitem__bloggername a {
  color: var(--color-blue);
  font-weight: 700;
}
.p-blogindex .c-blogindexitem__subtitle {
  font-size: 14px;
  line-height: 100%;
  margin: 0 0 1rem 30px;
}
.p-blogindex .c-blogindexitem__bloggerinfo {
  position: relative;
  margin-left: 140px;
  margin-bottom: 1rem;
}
.p-blogindex .c-blogindexitem__bloggername {
  font-size: 14px;
  line-height: 17px;
  margin: 1rem 0 0;
}
.p-blogindex .c-blogindexitem__bloggerphoto {
  position: absolute;
  bottom: 0px;
  left: -110px;
  width: 91px;
}
.p-blogindex .c-blogindexitem__bloggerphoto img {
  display: block;
}
.p-blogindex .c-blogindexitem__footer {
  display: none;
}
.p-blogindex .c-blogindexitem__bloggerinfocontainer a {
  text-decoration: underline;
}
@media (hover: hover) {
  .p-blogindex .c-blogindexitem__bloggerinfocontainer a:hover {
    text-decoration: none;
  }
}
@media (min-width: 1200px) {
  .p-blogindex .c-blogindexitem__bloggerinfocontainer {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }
  .p-blogindex .c-blogindexitem__bloggerinfocontainer > * {
    white-space: nowrap;
  }
  .p-blogindex .c-blogindexitem__bloggername {
    margin-top: 0;
  }
  .p-blogindex .c-blogindexitem__footer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .p-blogindex .c-blogindexitem__bloggerinfo,
  .p-blogindex .c-blogindexitem__subtitle {
    margin-bottom: 0;
  }
  .p-blogindex .c-blogindexitem__subtitle {
    margin: 0;
  }
}

.p-blogindex .c-licenseformcontact,
.p-blogdetails .c-licenseformcontact {
  display: grid;
  gap: 0 1rem;
  grid-template-columns: 150px 1fr;
  padding: 0 1rem;
}
.p-blogindex .c-licenseformcontact,
.p-blogindex .c-licenseformcontact a,
.p-blogindex .c-licenseformcontact .wvuaw-subtitle,
.p-blogdetails .c-licenseformcontact,
.p-blogdetails .c-licenseformcontact a,
.p-blogdetails .c-licenseformcontact .wvuaw-subtitle {
  color: #fff;
}
.p-blogindex .c-licenseformcontact__image,
.p-blogdetails .c-licenseformcontact__image {
  width: 100%;
  height: auto;
}
.p-blogindex .c-licenseformcontact__text,
.p-blogdetails .c-licenseformcontact__text {
  font-size: 24px;
  line-height: 100%;
  font-weight: 900;
}
.p-blogindex .c-licenseformcontact__icon,
.p-blogdetails .c-licenseformcontact__icon {
  font-size: 16px;
  color: var(--color-orange);
}
.p-blogindex .c-licenseformcontact__contactbutton,
.p-blogdetails .c-licenseformcontact__contactbutton {
  max-width: 250px;
}

.p-blogindex .c-intro {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 0 2rem;
  margin: 0 0 2rem;
  padding: 0 0 2rem;
  border-bottom: 1px dashed var(--color-orange);
}
.p-blogindex select {
  height: 48px;
  width: 100%;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 0 2rem 0 1rem;
  font-size: 16px;
  line-height: 32px;
  cursor: pointer;
}
.p-blogindex .h1 {
  margin-bottom: 0;
}

.wvauw-by__wrapper {
  position: absolute;
  display: flex;
  align-items: flex-end;
  bottom: 0;
  left: 1rem;
  transform: translateY(10px);
  margin-bottom: 1rem;
}

.wvauw-by__wrapper a {
  font-weight: 700;
}

.wvauw-by__wrapper a img {
  display: flex;
  margin-right: 1rem;
  width: 80px;
}

.wvauw-by__wrapper .wvauw-by__link {
  text-decoration: underline;
  margin-left: 5px;
}

.c-related__blogtitle {
  font-size: 20px;
}

.c-related__header {
  margin: 2rem 0;
}

@media (min-width: 900px) {
  .c-related__blogs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
  }
  .c-related__blogtitle {
    font-size: 24px;
    line-height: normal;
  }
  .c-related__image img {
    height: 230px;
    width: 100%;
  }
  .c-related__content .button--blog {
    margin-top: 2rem;
  }
  .wvauw-by__wrapper {
    transform: translateY(30px);
  }
  .wvauw-by__wrapper a img {
    width: 100px;
  }
  .p-blogindex__grid {
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 0 3rem;
  }
}
@media (min-width: 1100px) {
  .wvauw-by__wrapper {
    transform: translateY(10px);
  }
  .wvauw-by__wrapper a img {
    width: 120px;
  }
}
.p-blogdetails__contactblock {
  margin: 3rem 0;
}

.p-blogdetails .c-nav {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 1rem 1rem;
}
.p-blogdetails .c-nav .button--navigate, .p-blogdetails .c-nav .wh-form__button--navigate {
  width: auto;
  min-width: initial;
}

html.page-blog-details .c-header {
  margin: 1rem 0 2rem;
  white-space: nowrap;
}
html.page-blog-details .c-header,
html.page-blog-details .c-header a,
html.page-blog-details .c-header .wvuaw-subtitle {
  color: #fff;
  font-weight: 700;
}
html.page-blog-details .c-header a {
  color: var(--color-orange);
  text-decoration: underline;
}
@media (hover: hover) {
  html.page-blog-details .c-header a:hover {
    text-decoration: none;
  }
}
html.page-blog-details .c-header .wvuaw-subtitle {
  margin-bottom: 0;
  margin-left: 0.5rem;
  text-transform: initial;
}

@media (min-width: 900px) {
  .p-blogdetails__grid {
    display: grid;
    grid-template-columns: 1fr 400px;
    gap: 0 3rem;
  }
}