/* Bulma mixins */
.c-contactblock {
  background: var(--color-blue);
  padding-top: 24px;
  padding-bottom: 4px;
  z-index: 10;
  transform: translateY(-1px);
}
.c-contactblock .wvuaw-subtitle {
  font-weight: 600;
}

.c-contactblock__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  column-gap: 4rem;
}

.c-contactblock__subtitle,
.c-contactblock__title,
.c-contactblock .wvuaw-subtitle {
  color: #fff;
}

.c-contactblock__subtitle {
  font-weight: 700;
}

.c-contactblock__socials {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding-bottom: 2.5rem;
}

.c-contactblock__socialicon {
  width: 33px;
  height: 33px;
  border-radius: 100%;
  background: #fff;
  color: var(--color-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
}
@media (hover: hover) {
  .c-contactblock__socialicon:hover {
    background: var(--color-orange);
    color: #fff;
  }
}

.c-contactblock__contactdata,
.c-contactblock__contactdata a.wvuaw-subtitle {
  color: #fff;
  font-weight: 600;
}
.c-contactblock__contactdata,
.c-contactblock__contactdata .wvuaw-subtitle {
  text-transform: initial;
}
@media (hover: hover) {
  .c-contactblock__contactdata a.wvuaw-subtitle:hover {
    text-decoration: underline;
  }
}

.c-contactblock__icon {
  color: var(--color-orange);
  font-size: 20px;
}

.c-contactblock__contactbutton {
  max-width: 400px;
}

.c-contactblock__imagebottom {
  max-width: 197px;
  display: block;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .c-contactblock {
    position: relative;
    padding-top: 40px;
    padding-bottom: 70px;
  }
  .c-contactblock__clippathbottom {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    height: 10px;
    background: #fff;
    z-index: 1000;
  }
  .c-contactblock__container {
    grid-template-columns: 1fr 1fr;
    padding-right: 0;
    grid-template-columns: 400px 360px;
    column-gap: 70px;
  }
  .c-contactblock__contactbutton {
    max-width: 200px;
  }
  .c-contactblock__intro {
    display: grid;
    grid-template-columns: min-content 1fr;
    column-gap: 30px;
  }
  .c-contactblock__titles {
    order: 2;
  }
  .c-contactblock__socials {
    flex-direction: column;
  }
  .c-contactblock__socialicon {
    margin-bottom: 0.4em;
  }
  .c-contactblock__imagebottom {
    display: block;
    max-width: 274px;
    position: absolute;
    bottom: -10px;
    right: -20px;
    z-index: 10;
  }
}
@media (min-width: 1200px) {
  .c-contactblock__container {
    grid-template-columns: repeat(2, 500px);
    column-gap: 100px;
  }
  .c-contactblock {
    padding-bottom: 55px;
  }
  .c-contactblock__contactbutton {
    max-width: 320px;
  }
}
@media (min-width: 1280px) {
  .c-contactblock__imagebottom {
    right: 80px;
  }
}
@media (min-width: 1350px) {
  .c-contactblock {
    padding-bottom: 112px;
  }
  .c-contactblock__imagebottom {
    max-width: 330px;
    right: 30px;
  }
}
@media (min-width: 1450px) {
  .c-contactblock__imagebottom {
    right: 60px;
  }
}