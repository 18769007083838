/* Bulma mixins */
.headerimg__container {
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding-top: 80px;
  padding-bottom: 60px;
}

.headerimg__backgroundcontainer {
  position: relative;
}

.headerimg__background {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.headerimg__clippath {
  background: #fff;
  clip-path: polygon(0 72%, 98% 0, 100% 100%, 0% 100%);
  aspect-ratio: 1110/200;
  position: absolute;
  left: 50%;
  transform: translateX(calc(-50% - 10px));
  width: 100%;
  max-width: 1260px;
  bottom: -1px;
}
@media (min-width: 900px) {
  .headerimg__clippath {
    transform: translateX(calc(-50% - 20px));
  }
}
@media (min-width: 1270px) {
  .headerimg__clippath {
    max-width: initial;
    transform: translateX(calc(-50% - 100px));
  }
}

.headerimg__background--overlay {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(70, 121, 165, 0.8);
  width: 100%;
  left: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
}

.headerimg__titlewrapper {
  z-index: 5;
  max-width: 640px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

.headerimg__button {
  text-transform: initial;
}

.headerimg__title {
  font-size: 28px;
  line-height: 33px;
  color: #fff;
  margin-bottom: 0.5em;
}

.headerimg__subtitle {
  color: var(--color-orange);
  font-weight: 900;
}
.headerimg__subtitle:empty {
  height: 2rem;
  margin-bottom: 0;
}

.headerimg__customers {
  margin: 0 0 1rem 0;
}

.headerimg__customers--title {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: bold;
}

.headerimg__customers--people {
  filter: invert(74%) sepia(76%) saturate(7416%) hue-rotate(212deg) brightness(287%) contrast(171%);
}

@media (min-width: 700px) {
  .headerimg__container {
    padding-top: 100px;
    padding-bottom: 80px;
  }
  .headerimg__clippath {
    aspect-ratio: 1110/100;
  }
}
@media (min-width: 1024px) {
  .headerimg__container {
    padding-top: 120px;
    padding-bottom: 140px;
  }
  .headerimg__clippath {
    aspect-ratio: 1110/60;
  }
  .headerimg__title {
    font-size: 48px;
    line-height: 56px;
    margin-bottom: 0.3em;
  }
  .headerimg__subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 1rem;
  }
}
@media (min-width: 1280px) {
  .headerimg__container {
    padding-left: 1rem;
    padding-right: 0;
  }
}