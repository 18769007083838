@charset "UTF-8";
html.wait-for-pageload-squeezely body {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}

html.wait-for-pageload-squeezely.squeezely-done body {
  pointer-events: auto;
  opacity: 1;
}

body {
  opacity: 0;
}
body.show {
  opacity: 1;
}

/* bulma fixes */
strong {
  color: inherit;
}

.hamburger-inner:before {
  top: -8px;
}

.hamburger-inner:after {
  bottom: -8px;
}

a {
  transition: color 0.3s, background 0.3s, box-shadow 0.3s, transform 0.3s;
}

.wh-rtd ul:not(.custom) {
  position: relative;
  list-style: none;
  margin-left: 0;
}
.wh-rtd ul:not(.custom) > li {
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 6px;
}
.wh-rtd ul:not(.custom) > li:before {
  content: "";
  position: absolute;
  top: 7px;
  left: 0;
  display: block;
  color: var(--color-blue);
  background: var(--color-blue);
  width: 14px;
  height: 14px;
}

:root {
  --color-orange: #f6a713;
  --color-blue: #4679a5;
  --color-red: #ec6446;
  --color-black: #000;
  --color-orange-rgba: 246, 167, 19;
  --color-blue-rgba: 70, 121, 165;
  --color-red-rgba: 236, 100, 70;
  --grid-max-content-width: 1280px;
  --grid-padding-x: 24px;
  --input-height: 60px;
  --two-columns-display: flex;
  --two-columns-gap: 2rem;
}
@media (min-width: 1024px) {
  :root {
    --two-columns-display: grid;
  }
}
@media (min-width: 1280px) {
  :root {
    --two-columns-gap: 5rem;
  }
}

/* Bulma mixins */
/* Bulma mixins */
body {
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 30px;
  font-family: Raleway, sans-serif;
  color: var(--color-black);
}
@media (min-width: 1024px) {
  body {
    font-size: 16px;
    line-height: 30px;
  }
}

h1, .h1, h2, .h2, h3, .h3 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 900;
  color: var(--color-black);
}

.upper {
  text-transform: uppercase;
}

h1, .h1 {
  margin-bottom: 0.5em;
}

h2, .h2, h3, .h3 {
  margin-bottom: 0.5em;
}

p {
  margin-bottom: 1rem;
}

.wvuaw-subtitle {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  text-align: left;
  color: var(--color-blue);
  margin-bottom: 0.8em;
  text-transform: uppercase;
}
.wvuaw-subtitle--noupper {
  text-transform: initial;
}
@media (min-width: 1024px) {
  .wvuaw-subtitle {
    font-size: 16px;
    line-height: 19px;
  }
}

.wvuaw-subtitle--no-text-transform {
  text-transform: initial;
}

.wh-rtd > p {
  margin: 5px 0 1em;
}

p.normal a {
  text-decoration: underline;
}
@media (hover: hover) {
  p.normal a:hover {
    text-decoration: none;
  }
}

@media (min-width: 1024px) {
  h1, .h1 {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 0.5em;
  }
  h2, .h2, h3, .h3 {
    font-size: 42px;
    line-height: 50px;
    margin-bottom: 0.5em;
  }
}
.p-home__content {
  display: block !important;
}

:root {
  --squeezely-text-color: white;
  --squeezely-background-color: #4679a5;
  --squeezely-box-shadow: #00000029 0 3px 6px;
}

.sqzly-toaster, .sqzly-toaster-close:hover {
  background-color: var(--squeezely-background-color) !important;
  color: var(--squeezely-text-color) !important;
}

.sqzly-toaster-close {
  color: var(--squeezely-background-color) !important;
  background: var(--squeezely-text-color) !important;
}

.sqzl-timer-bar {
  background-color: var(--squeezely-text-color);
}

.sqzly-toaster-close {
  height: 30px !important;
  width: 30px !important;
  border-radius: 50% !important;
  position: absolute !important;
  top: 15px !important;
  right: 15px !important;
  z-index: 100 !important;
  font-size: 32px !important;
  line-height: 30px !important;
  padding: 0 0 0 0px !important;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.sqzly-toaster-close:hover {
  -webkit-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.sqzly-toaster h3 {
  text-align: center !important;
  margin-bottom: 20px !important;
  text-transform: uppercase !important;
  font-size: 28px !important;
}

.sqzly-toaster {
  max-width: 320px !important;
  font-size: 120% !important;
  padding: 40px !important;
  border: none !important;
  line-height: 1.6 !important;
  border-radius: 8px !important;
  z-index: 888 !important;
}

.sqzly-toaster li {
  padding-right: 20% !important;
  margin-bottom: 12px !important;
}

.sqzly-toaster ol {
  -webkit-padding-start: 20px !important;
  padding-inline-start: 20px !important;
}

.sqzly-toaster ::marker {
  font-size: 120% !important;
}

@media (max-width: 767px) {
  .sqzly-toaster {
    max-width: 94vw !important;
    border-radius: 0px !important;
    right: 0px !important;
    left: 2% !important;
    padding: 20px !important;
    border-radius: 20px !important;
    bottom: 25px !important;
    width: 96%;
  }
  .sqzly-body {
    max-height: 100% !important;
  }
  .sqzly-toaster li {
    padding-right: 0% !important;
    margin-bottom: 12px !important;
  }
  .sqzly-toaster-close {
    top: 15px !important;
    right: 15px !important;
    line-height: 32px !important;
    font-size: 32px !important;
    height: 32px !important;
    width: 32px !important;
  }
  .sqzly-toaster h3 {
    margin-bottom: 16px !important;
    -webkit-transform: translateX(-35px);
    -ms-transform: translateX(-35px);
    transform: translateX(-35px);
    font-size: 24px !important;
  }
}
html #sqzl-emulator {
  top: 100px !important;
  z-index: 2147483647 !important;
}

/*
.sqzly-toaster-close {
  color: #4679a5 !important;
  height: 25px !important;
  width: 25px !important;
  background: #fff !important;
  border-radius: 50% !important;
  position: absolute !important;
  top: 15px !important;
  right: 15px !important;
  z-index: 100 !important;
  font-size: 24px !important;
  line-height: 25px !important;
  padding: 0 0 0 4px !important;
}

.sqzly-toaster-close:hover {
  font-size: 20px !important;
  right: 15px !important;
  background-color: #4679a5 !important;
  transform: rotate(180deg) !important;
  color: inherit !important;
  transition: all 0.5s ease;
}

.sqzly-toaster h3 {
  text-align: center !important;
  color: white !important;
  margin-bottom: 20px !important;
  text-transform: uppercase !important;
  font-size: 18px !important;
}

.sqzly-toaster {
  max-width: 320px !important;
  font-size: 120% !important;
  padding: 40px !important;
  background-color: #4679a5 !important;
  color: white !important;
  border: none !important;
  line-height: 1.6 !important;
  border-radius: 8px !important;
  opacity: 1 !important;
  z-index: 888 !important;
}

.sqzly-toaster li {
  padding-right: 20% !important;
  margin-bottom: 12px !important;
}

.sqzly-toaster ol {
  padding-inline-start: 20px !important;
}

.sqzly-toaster ::marker {
  font-size: 120% !important;
}

@media (max-width: 767px) {
  .sqzly-toaster {
    max-width: 100vw !important;
    width: 100vw !important;
    border-radius: 0px !important;
    bottom: 0px !important;
    right: 0px !important;
    left: 0px !important;
    padding: 20px 20px 0px 20px !important;
  }

  .sqzly-toaster li {
    padding-right: 0% !important;
    margin-bottom: 12px !important;
  }

  .sqzly-toaster-close {
    top: 20px !important;
    right: 8vw !important;
  }

  .sqzly-toaster-close:hover {
    font-size: 20px !important;
    right: 8vw !important;
  }

  .sqzly-toaster h3 {
    margin-bottom: 16px !important;
    transform: translateX(-35px);
  }

  .sqzly-toaster h3::before {
    content: "🔔";
  }

  .sqzly-body {
    max-height: 0px !important;
    transition: all 0.8s ease;
  }

  .toasteropenbody {
    max-height: 300px !important;
  }

  .toasteropen {
    padding: 20px 10vw 10vw 10vw !important;
  }
}
*/
.hamburger {
  padding-left: 0;
  padding-right: 0;
  width: 30px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: var(--color-orange);
  width: 30px;
  height: 2px;
}

.hamburger-inner:before {
  top: -8px;
}

.hamburger-inner:after {
  bottom: -8px;
}

.container, .wh-rtd--main > h1,
.wh-rtd--main > h2,
.wh-rtd--main > h3,
.wh-rtd--main > p,
.wh-rtd--main > .wh-form,
.wh-rtd--main > .widget--threecolumns {
  width: 100%;
  max-width: calc(var(--grid-max-content-width) + 2rem) !important;
  padding: 0 var(--grid-padding-x);
  margin-left: auto !important;
  margin-right: auto !important;
}

.emb-textimagecols__cols {
  display: flex;
  justify-content: space-between;
}
.emb-textimagecols__cols--mirrored .emb-textimagecols__col--image {
  order: 1;
}
.emb-textimagecols__cols--mirrored .emb-textimagecols__col--text {
  padding-right: 0;
  padding-left: 20px;
  order: 2;
}
.emb-textimagecols__col--image {
  width: 50%;
}
.emb-textimagecols__col--text {
  display: flex;
  align-self: center;
  width: 50%;
  padding-right: 20px;
}
.emb-textimagecols__image {
  display: block;
  width: 100%;
}
.emb-textimagecols__text > *:first-child {
  margin-top: 0;
}
.emb-textimagecols__text > *:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .emb-textimagecols__title {
    margin-top: 0;
    padding-right: 0;
  }
  .emb-textimagecols__cols {
    flex-direction: column;
  }
  .emb-textimagecols__cols .emb-textimagecols__title,
  .emb-textimagecols__cols .emb-textimagecols__text, .emb-textimagecols__cols--mirrored .emb-textimagecols__title,
  .emb-textimagecols__cols--mirrored .emb-textimagecols__text {
    padding-left: 0;
    padding-right: 0;
  }
  .emb-textimagecols__cols--mirrored .emb-textimagecols__col--text {
    margin-top: 25px;
    padding-left: 0;
    padding-right: 0;
  }
  .emb-textimagecols__cols--mirrored .emb-textimagecols__col--image {
    margin-top: 0;
  }
  .emb-textimagecols__col {
    width: 100%;
  }
  .emb-textimagecols__col--text {
    padding-left: 0;
    padding-right: 0;
  }
  .emb-textimagecols__col--image {
    margin-top: 25px;
  }
}

.wh-form__prefillarea {
  display: none;
}

.wh-form__button[disabled], .wh-form__button[disabled=disabled] {
  background: #ddd;
  cursor: default;
}

#swal2-content {
  font-size: 16px;
  line-height: 140%;
}

.lds-ring-container {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  transition: opacity 0.3s;
}

html.async-hide .lds-ring-container {
  opacity: 1;
  pointer-events: auto;
}
html.async-hide .lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
html.async-hide .lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #386494;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #386494 transparent transparent transparent;
}
html.async-hide .lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
html.async-hide .lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
html.async-hide .lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #fff;
  border: 2px solid var(--color-blue);
  margin: 0 !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--color-blue);
}

@media (min-width: 1280px) {
  .w-video-licenseform__contactblock:after,
  .c-contactblock:after,
  .ctalicense:after {
    content: "";
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 71px;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    aspect-ratio: 71/366;
  }
  .w-video-licenseform__contactblock:after {
    clip-path: polygon(0 0, 100% 0, 0 100%);
    bottom: auto;
    top: 0;
    transform: translate(-10px, calc(-100% + 1px));
  }
  .c-contactblock:after {
    clip-path: polygon(100% 100%, 0 100%, 100% 0);
    left: auto;
    right: 0;
    bottom: -1px;
    display: block;
  }
}
@font-face {
  font-family: "icomoon";
  src: url("./web/fonts/icomoon.woff?nnfk4o") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.ww-dialog p {
  line-height: 140%;
}
.ww-dialog p:first-child {
  display: flex;
  gap: 10px;
}
.ww-dialog p:first-child:before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f071";
  font-size: 20px;
  color: red;
}

[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-checkmark:before {
  content: "\e900";
}

.icon-close:before {
  content: "\e901";
}

.icon-phone:before {
  content: "\e942";
}

.icon-envelop:before {
  content: "\e945";
}

.icon-location:before {
  content: "\e947";
}

.icon-eye:before {
  content: "\e9ce";
}

.icon-star-empty:before {
  content: "\e9d7";
}

.icon-star-half:before {
  content: "\e9d8";
}

.icon-star-full:before {
  content: "\e9d9";
}

.icon-facebook2:before {
  content: "\ea91";
}

.icon-instagram:before {
  content: "\ea92";
}

.icon-whatsapp:before {
  content: "\ea93";
}

.icon-twitter:before {
  content: "\ea96";
}

html.page-thank-you-subscribe .w-reasons__contactblock .floating-arrow {
  display: none !important;
}