/* Bulma mixins */
.w-video-licenseform * {
  text-align: center;
}

.w-video-licenseform__container {
  position: relative;
  z-index: 20;
}

.w-video-licenseform__container,
.ctalicense__container {
  padding-left: 24px;
  padding-right: 24px;
}

.w-video_licenseform__title {
  margin-bottom: 0.2em;
}

.w-video_licenseform__subtitle {
  margin-bottom: 2rem;
}

.w-video-licenseform__video {
  width: 100%;
  aspect-ratio: 16/9;
}

.w-video-licenseform__videotrigger {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.w-video-licenseform__playbutton {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border: 6px solid #fff;
  border-radius: 50%;
  color: #f5f5f5;
  transition: background 0.3s, box-shadow 0.3s, text-shadow 0.3s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (hover: hover) {
  .w-video-licenseform__playbutton:hover {
    background-color: var(#fff);
    box-shadow: 0px 0px 10px var(#fff);
    text-shadow: 0px 0px 10px var(#fff);
  }
  .w-video-licenseform__playbutton:hover .w-video-licenseform__playbuttonicon {
    border-color: transparent transparent transparent #fff;
  }
}

.w-video-licenseform__playbuttonicon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20.5px 0 20.5px 35px;
  border-color: transparent transparent transparent #fff;
  transition: 0.3s;
  border-style: solid;
  width: 0px;
  height: 0px;
  line-height: 0px;
  display: block;
  transform: translateX(5px);
}

.w-video-licenseform__contactblock {
  position: relative;
  z-index: 10;
  transform: translateY(-20px);
}

.w-video-licenseform__contactblock:before {
  content: "";
  background: var(--color-blue);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  aspect-ratio: 375/50;
  transform: translateY(calc(-100% + 1px));
}

@media (min-width: 800px) {
  .w-video-licenseform__contactblock {
    margin-bottom: 100px;
  }
  .w-video-licenseform__contactblock .floating-arrow {
    display: block;
    position: absolute;
    bottom: 0;
    left: 30vw;
    transform: translateY(100%) scaleX(-1);
  }
}