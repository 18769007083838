/* Bulma mixins */
.subscribe-nav {
  display: flex;
  margin-bottom: 3rem;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}
.subscribe-nav::after {
  content: "";
  position: absolute;
  height: 3px;
  background: var(--color-blue);
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
}
.subscribe-nav *[data-step] {
  cursor: pointer;
}

.subscribe-nav__title {
  color: var(--color-blue);
  font-weight: bold;
}
.subscribe-nav__title.selected {
  color: var(--color-orange);
}

.subscribe-nav__title--last {
  text-align: right;
}

.subscribe-nav__title--link {
  cursor: pointer;
  transition: color 0.3s;
}
@media (hover: hover) {
  .subscribe-nav__title--link:hover {
    color: var(--color-orange);
  }
}

.subscribe-nav__line,
.subscribe-nav__block {
  transition: background 0.3s;
  background: var(--color-blue);
}
.subscribe-nav--done .subscribe-nav__line, .subscribe-nav__line.selected,
.subscribe-nav--done .subscribe-nav__block,
.subscribe-nav__block.selected {
  background: var(--color-orange);
}

.subscribe-nav__line {
  height: 2px;
  position: relative;
  pointer-events: none;
}

.subscribe-nav__line,
.subscribe-nav__spacer,
.subscribe-nav__title {
  display: none;
}

.subscribe-nav__block {
  width: 14px;
  height: 14px;
  background: var(--color-blue);
  position: absolute;
  left: -1px;
  top: 50%;
  transform: translateY(calc(-50% - 0.5px));
  pointer-events: auto;
}

.subscribe-nav__line--block-end .subscribe-nav__block {
  left: auto;
  right: 0;
}

.subscribe-nav--done {
  display: none;
}
.subscribe-nav--done, .subscribe-nav--done * {
  pointer-events: none !important;
}
.subscribe-nav--done .subscribe-nav__title {
  color: var(--color-blue) !important;
}
.subscribe-nav--done .subscribe-nav__title--last {
  color: var(--color-orange) !important;
}

.subscribe-nav__number {
  line-height: 24px;
  z-index: 10;
  position: relative;
  background: #fff;
  color: #89b5da;
  border-radius: 50%;
  font-weight: 600;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37px;
  height: 37px;
  border: 2px solid #89b5da;
  margin: 0 15px 0 15px;
  padding: 0 0 6px 0px;
  transition: background 0.3s, border 0.3s, color 0.3s;
}
.subscribe-nav__number[data-step="0"] {
  margin-left: 0;
}

.subscribe-nav__number.selected {
  background: #f6a713;
  border-color: #f6a713;
  color: #fff;
}

.subscribe-nav__number--last {
  margin-right: 0;
}

@media (min-width: 760px) {
  .subscribe-nav__number,
  .subscribe-nav:after {
    display: none;
  }
  .subscribe-nav {
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: auto auto;
    column-gap: 2px;
    row-gap: 10px;
    overflow: visible;
  }
  .subscribe-nav.init {
    display: grid;
  }
  .subscribe-nav__line,
  .subscribe-nav__spacer,
  .subscribe-nav__title {
    display: block;
  }
}