/* Bulma mixins */
.w-twocolumnstext--left {
  margin-bottom: 1rem;
}

.w-twocolumnstext__title {
  margin-bottom: 0.7em;
}

@media (min-width: 1024px) {
  .w-twocolumnstext {
    display: var(--two-columns-display);
    flex-direction: column;
    column-gap: var(--two-columns-gap);
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "subtitle ." "title ." "text-left text-right";
  }
  .w-twocolumnstext__subtitle {
    grid-area: subtitle;
  }
  .w-twocolumnstext__title {
    grid-area: title;
  }
  .w-twocolumnstext--left {
    margin-bottom: 0;
    grid-area: text-left;
  }
  .w-twocolumnstext--right {
    grid-area: text-right;
  }
}