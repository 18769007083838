/* Bulma mixins */
.scrollup {
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Raleway, sans-serif;
  font-size: 12px;
  line-height: 100%;
  color: var(--color-blue);
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  z-index: 99999;
  mix-blend-mode: multiply;
}
html.show-scroll-up .scrollup {
  opacity: 1;
  pointer-events: auto;
}
.scrollup svg {
  margin-bottom: 7px;
  transition: transform 0.3s;
}
@media (hover: hover) {
  .scrollup:hover svg {
    transform: scale(1.15) translateY(0px);
  }
}

@media (min-width: 1000px) {
  .scrollup {
    right: 2rem;
    bottom: 4rem;
  }
}
@media (min-width: 1450px) {
  .scrollup {
    right: 3rem;
    bottom: 2rem;
  }
}