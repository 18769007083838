/* Bulma mixins */
.textwithbgimage {
  position: relative;
}

.textwithbgimage__container {
  position: relative;
  z-index: 10;
  background: #fff;
  transform: translateY(-40px);
  max-width: 90% !important;
  margin: 0 auto;
  padding: 1rem 1rem 0;
}

.textwithbgimage__bgimage {
  width: 100%;
  height: 300px;
  margin-bottom: 0;
}

.textwithbgimage__bgimage:before {
  content: "";
  background: #fff;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  clip-path: polygon(100% 0, 0% 100%, 0 0);
  aspect-ratio: 375/22;
  z-index: 10;
}

.textwithbgimage--mirrored {
  display: flex;
  flex-direction: column;
}
.textwithbgimage--mirrored .textwithbgimage__bgimage {
  order: 2;
  margin-bottom: 0;
  margin-top: 6px;
}

@media (min-width: 769px) {
  .textwithbgimage__bgimage {
    height: 300px;
  }
}
@media (min-width: 1024px) {
  .widget--googlereviews .w-googlereviews,
  .widget--reasons .w-reasons {
    position: relative;
  }
  .textwithbgimage {
    --extra-background-margin-bottom: 130px;
    margin: 0 auto var(--extra-background-margin-bottom) auto;
  }
  .textwithbgimage .h1 {
    margin-bottom: 0.5em;
  }
  .textwithbgimage__container {
    transform: translateY(0);
    margin-right: 0 !important;
    max-width: 686px !important;
    padding-left: 5vw;
    padding-bottom: 2rem;
  }
  .textwithbgimage__container > * {
    max-width: 510px;
  }
  .textwithbgimage__bgimage {
    position: absolute !important;
    height: calc(100% + var(--extra-background-margin-bottom));
    left: 0;
    top: 0;
    width: 60vw;
    z-index: 10;
    margin-bottom: 0;
  }
  .textwithbgimage--mirrored .textwithbgimage__container {
    transform: translateY(0);
    margin-left: 0 !important;
    margin-right: auto !important;
  }
  .textwithbgimage--mirrored .textwithbgimage__bgimage {
    left: auto;
    right: 0;
    margin-top: 0;
  }
}
@media (min-width: 1250px) {
  .textwithbgimage__bgimage {
    width: 70vw;
  }
}