/* Bulma mixins */
html.page-home .p-reviews > span,
html.page-home-2 .p-reviews > span {
  display: block;
  width: 100%;
}
html.page-home .p-reviews .__fbcw__widget--small,
html.page-home-2 .p-reviews .__fbcw__widget--small {
  width: 100% !important;
  display: inline-flex !important;
  flex-direction: column;
  max-width: 100% !important;
}
html.page-home .p-reviews .__fbcw__widget--small *,
html.page-home-2 .p-reviews .__fbcw__widget--small * {
  font-family: "Raleway", "sans-serif" !important;
  line-height: 22px !important;
  color: #626262 !important;
}
html.page-home .p-reviews .__fbcw__widget .__fbcw__review.__fbcw__review--small .__fbcw__review__content,
html.page-home-2 .p-reviews .__fbcw__widget .__fbcw__review.__fbcw__review--small .__fbcw__review__content {
  max-height: initial !important;
  overflow: visible !important;
}
html.page-home .p-reviews .__fbcw__widget .__fbcw__widget__container.__fbcw__widget__container--small,
html.page-home-2 .p-reviews .__fbcw__widget .__fbcw__widget__container.__fbcw__widget__container--small {
  width: 100% !important;
}
html.page-home .p-reviews .__fbcw__widget .__fbcw__widget__rating-overview,
html.page-home-2 .p-reviews .__fbcw__widget .__fbcw__widget__rating-overview {
  margin-top: 12px !important;
  order: 2;
}
html.page-home .p-reviews .__fbcw__widget__review-holder,
html.page-home-2 .p-reviews .__fbcw__widget__review-holder {
  order: 3;
}
html.page-home .p-reviews .__fbcw__widget__rating-overview,
html.page-home .p-reviews .__fbcw__widget__review-holder,
html.page-home-2 .p-reviews .__fbcw__widget__rating-overview,
html.page-home-2 .p-reviews .__fbcw__widget__review-holder {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
html.page-home .p-reviews .__fbcw__widget .__fbcw__widget__divider,
html.page-home-2 .p-reviews .__fbcw__widget .__fbcw__widget__divider {
  margin: 0 !important;
  max-width: 100% !important;
}

.p-home__container {
  gap: 20px;
  display: flex;
  flex-direction: column-reverse;
  --grid-padding-x: 40px;
}

.p-home__slogan {
  display: none;
}

.p-home__background--container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 2rem 0 5rem;
}

.p-home__background {
  display: block;
  clip-path: polygon(100% 0, 100% 100%, 98% 100%, 94% 85%, 0 100%, 0 0);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: var(--color-blue) !important;
}

.p-home__background-overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(70, 121, 165, 0.5);
  width: 100%;
  left: 0;
  bottom: 0;
}
.p-home__background-overlay.show {
  display: block;
}

.p-home__mobileslogan {
  color: #fff;
  font-weight: 800;
  text-align: center;
}

.p-home__subtitle {
  color: var(--color-orange);
  font-weight: 900;
  text-align: center;
}

.p-home__cta {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  padding: 2rem 1rem;
  z-index: 5;
  order: 2;
}

@media (min-width: 500px) {
  .p-home__background {
    clip-path: polygon(100% 0, 100% 100%, 98% 100%, 96% 79%, 0 100%, 0 0);
  }
}
@media (min-width: 700px) {
  .p-home__background--container {
    padding-top: 50px;
    padding-bottom: 90px;
  }
}
@media (min-width: 1024px) {
  .p-home__background {
    clip-path: polygon(100% 0, 100% 100%, 95% 100%, 94% 80%, 0 100%, 0 0);
  }
  .p-home__background--container {
    padding-top: 150px;
    padding-bottom: 170px;
  }
  .p-home__cta {
    order: 0;
  }
  .p-home__container {
    column-gap: 50px;
    max-width: var(--grid-max-content-width);
    flex-direction: row;
    align-items: center;
    display: grid;
    grid-template-columns: 6fr 4fr;
    height: 100%;
    margin: 0 auto;
  }
  .p-home__mobileslogan,
  .p-home__subtitle {
    text-align: left;
    width: unset;
  }
  .licenseform__outer--header .licenseform {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: auto;
    column-gap: 20px;
    grid-template-areas: "title title" "formwrapper formwrapper" "forgot customers";
  }
  .licenseform__outer--header .licenseform__title {
    text-align: center;
    grid-area: title;
  }
  .licenseform__outer--header .licenseform__plate--formwrapper {
    grid-area: formwrapper;
  }
  .licenseform__outer--header .licenseform__plate--customers {
    margin-bottom: 0px;
    grid-area: customers;
  }
  .licenseform__outer--header .licenseform__forgotcontainer {
    grid-area: forgot;
  }
  .licenseform__outer--header .licenseform__forgot {
    text-align: center;
    text-decoration: underline;
  }
}
@media (min-width: 1024px) and (hover: hover) {
  .licenseform__outer--header .licenseform__forgot:hover {
    text-decoration: none;
  }
}