/* Bulma mixins */
.button,
.wh-form__button {
  font-size: 16px;
  line-height: 100%;
  height: auto;
  background: var(--color-orange);
  padding: 20px 15px;
  color: #fff;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 0;
  min-height: var(--input-height);
  text-decoration: none;
  cursor: pointer;
  text-transform: uppercase;
  min-width: 200px;
  transition: background 0.3s, color 0.3s, border 0.3s;
}
@media (hover: hover) {
  .button:hover,
  .wh-form__button:hover {
    background: var(--color-blue);
    color: #fff;
  }
}
@media (hover: hover) {
  .button--bluebg:hover,
  .wh-form__button--bluebg:hover {
    background: var(--color-red);
    color: #fff;
  }
}
.button .fas, .button .far,
.wh-form__button .fas,
.wh-form__button .far {
  margin-left: 5px;
}
.button--red,
.wh-form__button--red {
  background: var(--color-red);
  border: 0;
}
@media (hover: hover) {
  .button--red:hover,
  .wh-form__button--red:hover {
    background: var(--color-orange) !important;
    color: #fff;
  }
}
.button--blue, .button--blog,
.wh-form__button--blue,
.wh-form__button--blog {
  background: var(--color-blue);
}
@media (hover: hover) {
  .button--blue:hover, .button--blog:hover,
  .wh-form__button--blue:hover,
  .wh-form__button--blog:hover {
    background: var(--color-orange);
    color: #fff;
  }
}
.button--next,
.wh-form__button--next {
  margin-left: auto;
}
.button--small,
.wh-form__button--small {
  min-width: initial;
  padding: 16px 30px;
  min-height: initial;
}
.button--navigate,
.wh-form__button--navigate {
  position: relative;
  margin: 0 1px;
  color: #000;
  background: #dfebf2;
  width: 50%;
  font-size: 18px;
  line-height: 36px;
}
@media (hover: hover) {
  .button--navigate:hover,
  .wh-form__button--navigate:hover {
    background: var(--color-orange);
  }
}
.button--navigate-prev span,
.wh-form__button--navigate-prev span {
  position: absolute;
  left: 10px;
}
.button--navigate-next span,
.wh-form__button--navigate-next span {
  position: absolute;
  right: 10px;
}
@media screen and (max-width: 450px) {
  .button--navigate,
  .wh-form__button--navigate {
    font-size: 12px;
    line-height: 100%;
  }
}