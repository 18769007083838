/* Bulma mixins */
.footerlicense {
  margin-top: 50px;
  margin-bottom: 50px;
}
.footerlicense__container {
  position: relative;
}
.footerlicense__imagebrand {
  position: absolute;
  height: 144px;
  width: 108px;
  right: 0px;
  top: 42px;
  z-index: 4;
  background: url("../../web/img/image_right_brand.png") no-repeat;
}
.footerlicense__title {
  width: 100%;
  flex: 1;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 30px;
}
.footerlicense__formcontainer {
  background: url("../../web/img/footer_license.png") no-repeat 30% 70%;
  height: 420px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 70px 30px 0 70px;
  border-radius: 10px;
}
.footerlicense__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(var(--color-blue), 0.8);
  border-radius: 10px;
}
.footerlicense__form {
  height: 290px;
  width: 450px;
  background: #eef2f5;
  z-index: 4;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.footerlicense__badge {
  position: relative;
  width: 299px;
  height: 299px;
}
.footerlicense__badge img {
  display: block;
  width: 100%;
  position: absolute;
  top: 70px;
}
.footerlicense__formtitle {
  font-size: 36px;
  line-height: 48px;
  font-weight: 700;
  color: var(--color-blue);
  margin-bottom: 25px;
}
.footerlicense__forgot {
  color: var(--color-blue);
}
@media screen and (max-width: 870px) {
  .footerlicense__imagebrand {
    display: none;
  }
  .footerlicense__formcontainer {
    padding-left: 40px;
  }
  .footerlicense__badge {
    width: 229px;
    height: 229px;
  }
}
@media screen and (max-width: 740px) {
  .footerlicense__badge {
    display: none;
  }
  .footerlicense__formcontainer {
    justify-content: center;
  }
}
@media screen and (max-width: 450px) {
  .footerlicense__container {
    padding-left: 10px;
    padding-right: 10px;
  }
  .footerlicense__overlay, .footerlicense__title {
    display: none;
  }
  .footerlicense__formcontainer {
    background: none;
    padding: 0;
  }
  .footerlicense__form {
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    background: none;
  }
}