/* Bulma mixins */
.w-reasons__steps {
  display: block;
}

.w-reasons__step img {
  margin: 0 auto;
  display: block;
  width: 119px;
  max-width: 100%;
}
.w-reasons__step p {
  text-align: center;
  line-height: 25px !important;
  margin-bottom: 0;
}

.w-reasons .textwithbgimage__bgimage {
  position: relative;
  margin-top: 1rem;
}

.w-reasons .textwithbgimage__bgimage:before {
  content: "";
  background: #fff;
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  clip-path: polygon(100% 0, 0% 100%, 0 0);
  aspect-ratio: 375/98;
  z-index: 10;
}

.w-reasons__contactblock .c-contactblock {
  position: relative;
}

.w-reasons__contactblock .c-contactblock:before {
  content: "";
  background: #fff;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  clip-path: polygon(100% 100%, 0% 100%, 0 0);
  aspect-ratio: 375/50;
  z-index: 1000;
}

@media (min-width: 500px) {
  .w-reasons .textwithbgimage__bgimage:before {
    aspect-ratio: 375/80;
  }
  .w-reasons__steps {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 30px;
  }
}
@media (min-width: 800px) {
  .w-reasons__contactblock {
    margin-bottom: 45px;
    position: relative;
  }
  .w-reasons__contactblock .floating-arrow {
    display: block;
    position: absolute;
    bottom: 0;
    right: 40vw;
    transform: translateY(calc(100% - 10px));
  }
  .w-reasons .textwithbgimage__bgimage:before {
    aspect-ratio: 375/50;
  }
}
@media (min-width: 1024px) {
  .w-reasons--hidelicenseform .textwithbgimage--mirrored {
    margin-bottom: calc(var(--extra-background-margin-bottom) + 40px);
  }
  .w-reasons__steps {
    column-gap: 70px;
  }
  .w-reasons__step img {
    width: 135px;
  }
  .w-reasons .textwithbgimage__bgimage {
    margin-top: 0;
  }
  .w-reasons__contactblock .c-contactblock:before {
    aspect-ratio: 375/20;
  }
}
@media (min-width: 1216px) {
  .w-reasons__step img {
    width: 160px;
  }
}