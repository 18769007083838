/* Bulma mixins */
.c-quote {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: var(--color-blue);
  padding: 3rem 2rem 2rem;
  text-align: center;
}
.c-quote::before {
  clip-path: polygon(0 1%, 0% 100%, 100% 100%);
  content: "";
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  aspect-ratio: 780/50;
  background: var(--color-blue);
  transform: translateY(-100%);
}
.c-quote img {
  position: absolute;
  top: 0;
  transform: translate(-50%, calc(-50% - 10px));
  left: 50%;
  width: 120px;
  height: 120px;
}
.c-quote__quote {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  font-style: italic;
  color: #fff;
  margin: 1rem 0 0;
  padding: 0;
  width: 100%;
}
@media (min-width: 800px) {
  .c-quote {
    padding-top: 2rem;
    flex-direction: row;
    justify-content: center;
    column-gap: 1rem;
  }
  .c-quote img {
    position: static;
    transform: none;
  }
  .c-quote__quote {
    margin-top: 0;
  }
}