/* Bulma mixins */
.footer {
  padding: 0px;
  background: #fff;
}

.footer .footer__title {
  color: var(--color-blue);
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 16px;
  font-size: 16px;
}

.footer__socials--icon {
  background: #F6A713;
  padding: 7px;
  border-radius: 99px;
}

.footer .icon-phone,
.footer .icon-envelop,
.footer .icon-location {
  color: var(--color-blue);
  margin-right: 7px;
}

.footer__container {
  padding: 75px 0 50px;
  clip-path: polygon(0 0, 100% 4%, 100% 100%, 0% 100%);
}

.footer__links {
  list-style: none !important;
}

.footer__title {
  position: relative;
}

.footer__title.active {
  position: relative;
}
.footer__title.active .footer__closelinks {
  transform: translateY(-50%) scaleY(-1);
}

.footer__links.hide {
  display: none;
}

.footer .js-footer-close-quicklinks {
  cursor: pointer;
}

.footer__closelinks {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}
.footer__closelinks svg {
  display: block;
}
.footer__closelinks .js-footer-close-quicklinks.active {
  transform: scaleY(-1);
}

@media (min-width: 600px) {
  .footer__closelinks {
    display: none;
  }
}
@media (min-width: 1024px) {
  .footer__container {
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
    padding: 100px 0 25px;
  }
  .footer__links li {
    white-space: pre;
  }
}
@media (min-width: 1150px) {
  .footer__links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 3em;
  }
}
.footer__container {
  background: #D8E2EC;
  color: #000;
}
.footer__logocontainer {
  text-align: center;
}
.footer__logocontainer img {
  max-width: 250px;
}
.footer__copyright {
  text-align: center;
  color: #000;
  margin: 15px 0;
}
.footer__copyright a {
  text-decoration: none;
  color: inherit;
}
@media (hover: hover) {
  .footer__copyright a:hover {
    text-decoration: underline;
  }
}
@media (hover: hover) {
  .footer__copyright a:hover {
    color: var(--color-blue);
  }
}
@media (min-width: 1000px) {
  .footer__copyright {
    display: flex;
    justify-content: space-between;
  }
}
.footer__title {
  font-size: 20px;
  line-height: 100%;
  font-weight: 800;
  margin: 40px 0 25px;
}
.footer__icon {
  margin-left: -13px;
}
.footer__content {
  font-size: 14px;
  line-height: 30px;
}
.footer__content a {
  color: #000;
  text-decoration: none;
}
.footer__socials {
  margin-top: 30px;
}
.footer__socials--icon {
  font-size: 21px;
  line-height: 100%;
  color: #fff;
  margin: 0 10px 0 0;
  text-decoration: none;
}
.footer__links {
  list-style: none;
  padding-left: 0;
}
.footer__link {
  color: #000;
  font-size: 14px;
  line-height: 30px;
  text-decoration: none;
}
@media (min-width: 600px) {
  .footer__cols {
    display: grid;
    gap: 0 2rem;
    grid-template-columns: 1fr 1fr;
  }
  .footer__col--links {
    grid-column: 1/3;
  }
  .footer__col--links .footer__links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 2rem;
  }
}
@media (min-width: 1024px) {
  .footer__cols {
    grid-template-columns: 3fr 3fr 4fr;
  }
  .footer__col--links {
    grid-column: initial;
  }
}

.footer__copyrightlinks {
  display: flex;
  flex-direction: column;
}

.footer__copyrightdivider {
  display: none;
}

@media (min-width: 600px) {
  .footer__copyrightlinks {
    flex-direction: row;
    justify-content: center;
  }
  .footer__copyrightdivider {
    display: inline-block;
    margin: 0 5px;
  }
}