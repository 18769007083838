/* Bulma mixins */
.p-faq details {
  margin: 0 auto;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  overflow: hidden;
  border: solid 1px #E9E9E9;
}
.p-faq details[open] {
  border-color: #4679A5;
  background-color: #F6F6F6;
}
.p-faq summary {
  font-size: 16px;
  line-height: 26px;
  padding: 1rem 2rem 1rem 1rem;
  display: block;
  position: relative;
  cursor: pointer;
  font-weight: bold;
}
.p-faq summary svg {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  transition: transform 0.3s;
}
.p-faq .p-faq__answer {
  padding: 0 1rem 1.5rem 1rem;
}
.p-faq details[open] > summary svg {
  transform: translateY(-50%) scaleY(-1);
}
.p-faq details > ul {
  padding-bottom: 1rem;
  margin-bottom: 0;
}

.p-faq__categories {
  display: flex;
  justify-content: space-between;
  position: sticky;
  padding: 1rem 0;
  background: #fff;
  z-index: 9999;
}

.p-faq__categorieslist {
  width: 100%;
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.p-faq__category {
  color: var(--color-blue);
  position: relative;
  padding: 4px 0 4px 1rem;
}
.p-faq__category:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  transition: background 0.3s;
}
.active .p-faq__category {
  font-weight: bold;
}
.active .p-faq__category:before {
  background: var(--color-orange);
}

.p-faq--detail__subtitle {
  font-size: 16px;
  color: #4679A5;
  font-weight: normal;
  text-transform: uppercase;
}

@media (min-width: 800px) {
  .p-faq__title {
    margin-bottom: 1rem;
  }
  .p-faq__categorieslistitem {
    padding: 1rem 0;
  }
  .p-faq__categorieslistitem:first-child {
    padding-top: 3rem;
  }
  .p-faq__categories {
    position: sticky;
    top: 0;
    left: 0;
    width: 300px;
    height: 0;
    padding: 0;
  }
  .p-faq__categorieslist {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .p-faq__categorieslist::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1000px;
    height: 280px;
    clip-path: polygon(94% 0, 100% 72%, 100% 89%, 0 100%, 0 14%);
    background: var(--color-blue);
  }
  .p-faq__category {
    color: #fff;
    padding-left: 1.5rem;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 1rem;
  }
}
@media (min-width: 800px) and (hover: hover) {
  .p-faq__category:hover {
    color: var(--color-orange);
  }
}
@media (min-width: 800px) {
  .p-faq__questions {
    position: sticky;
    margin-left: 350px;
    top: 188px;
    transform: translateY(-15px);
  }
  .p-faq__grid {
    position: relative;
  }
}