/* Bulma mixins */
.w-sendphotos {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.w-sendphotos .button {
  padding-left: 0;
  padding-right: 0;
  text-transform: initial;
  font-size: 18px;
  margin-bottom: 0;
  margin-right: 2rem;
  color: #fff;
  width: 100%;
  min-width: 0;
}
.w-sendphotos .button:last-child {
  margin-right: 0;
}
.w-sendphotos__buttontitle {
  margin-left: 12px;
}
.w-sendphotos__text--whatsapp {
  background: #25D366;
}
@media (hover: hover) {
  .w-sendphotos__text--whatsapp:hover {
    color: #fff;
    background: rgb(29.6, 168.8, 81.6) !important;
  }
}
.w-sendphotos__text--email {
  background: #EC6446;
}
@media (hover: hover) {
  .w-sendphotos__text--email:hover {
    color: #fff;
    background: rgb(194.25, 51.45, 19.95) !important;
  }
}
.w-sendphotos__icon {
  font-size: 16px;
}

.w-sendphotos__buttontitle {
  font-size: 16px;
}

@media (min-width: 600px) {
  .w-sendphotos {
    margin-bottom: 5rem;
  }
}
@media (min-width: 1120px) {
  .w-sendphotos {
    flex-direction: row;
    column-gap: 1rem;
  }
  .w-sendphotos .button {
    margin-right: 0;
  }
}