/* Bulma mixins */
.about__container {
  background: #fff;
}
.about__container--grey {
  background: #F5F4F0;
}
.about__container--with-image {
  align-items: flex-start;
}
.about__container--with-full-image {
  overflow: hidden;
  max-height: 500px;
}
.about__container--with-full-image__img {
  width: 100%;
  transform: translateY(-150px);
}
@media only screen and (max-width: 1098px) {
  .about__container--with-full-image__img {
    transform: unset;
  }
}
@media only screen and (min-width: 1440px) {
  .about__container--with-full-image__img {
    transform: translateY(-250px);
  }
}
@media only screen and (min-width: 1700px) {
  .about__container--with-full-image__img {
    transform: translateY(-310px);
  }
}
@media only screen and (min-width: 1700px) {
  .about__container--with-full-image__img {
    transform: translateY(-310px);
  }
}
.about__content {
  font-size: 16px;
  max-width: 1200px;
  margin: 50px auto;
}
.about__content--center {
  text-align: center;
}
.about__title--highlight {
  text-transform: uppercase;
  color: #ffa500;
}
.about__text {
  max-width: 60%;
}
.about__text--wide {
  max-width: 90%;
}
.about__image {
  width: 40%;
  margin: 0 5em;
  align-self: center;
  justify-self: center;
}
.about__image img {
  display: block;
  width: 100%;
}
.about__image--small {
  width: 10%;
}
@media only screen and (max-width: 1250px) {
  .about__text {
    margin: 0 30px;
  }
}
@media only screen and (max-width: 968px) {
  .about__content {
    flex-direction: column;
  }
  .about__text {
    margin: 20px auto;
  }
  .about h2 {
    font-size: 22px;
  }
}