/* Bulma mixins */
.c-review__container {
  border: 1px solid #e9e9e9;
  padding: 2rem;
}

.c-review.bluebg {
  position: relative;
}
.c-review.bluebg .c-review__container {
  border: 0;
  background: var(--color-blue);
}
.c-review.bluebg * {
  color: #fff;
}
.c-review.bluebg .c-review__topblueclip {
  clip-path: polygon(0 1%, 0% 100%, 100% 100%);
  position: relative;
  top: 1px;
  width: 100%;
  aspect-ratio: 780/50;
  background: var(--color-blue);
}
.c-review.bluebg .c-review__container {
  display: flex;
  flex-direction: column;
}
.c-review.bluebg .c-review__container > p {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  font-style: italic;
}
.c-review.bluebg .c-review__score {
  display: none !important;
}
.c-review.bluebg .c-review__subheader {
  margin-bottom: 0 !important;
  order: 2;
}
.c-review.bluebg .c-review__name {
  color: var(--color-orange);
}

.c-review.bluebg.left .c-review__topblueclip {
  transform: scaleX(-1);
}

.c-review .icon-star {
  color: var(--color-orange);
  font-size: 20px;
}

.c-review__creationdate {
  font-weight: 500;
  color: #000;
}

.c-review__divider {
  margin: 0 10px;
  color: var(--color-orange);
}

.c-review__score {
  font-size: 18px;
  line-height: 100%;
  font-weight: 700;
}

@media (min-width: 800px) {
  .c-review:nth-child(2) {
    padding-top: 3rem;
  }
}
html.page-experience .headerimg__container {
  margin-bottom: 0;
}
html.page-experience .p-experience #experiences-container {
  opacity: 0;
  min-height: 600px;
}
html.page-experience .p-experience #experiences-container.show {
  opacity: 1;
  min-height: initial;
}
html.page-experience .p-experience #experiences-container.fallback {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}
html.page-experience .p-experience__intro {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem 2rem;
}
@media (min-width: 800px) {
  html.page-experience .p-experience__intro {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
  html.page-experience .p-experience__googlereviewslogo {
    width: 185px;
  }
}
@media (min-width: 1280px) {
  html.page-experience .p-experience__introtext {
    margin-left: 141px;
  }
}